<template>
	<div class="nav-content">
		<router-link active-class="active" class="nav" to="/" exact>首页</router-link>
		<router-link active-class="active" class="nav" to="/seal-query">印章查询</router-link>
		<router-link active-class="active" class="nav" to="/verification-center">验证中心</router-link>
		<router-link active-class="active" class="nav" to="/product-center">产品中心</router-link>
		<router-link active-class="active" class="nav" to="/contact-us">联系我们</router-link>
	</div>
</template>

<script>
	export default {
		name: 'navigation'
	}
</script>

<style lang="scss" scoped>
	.nav-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 65px;
		background-color: #0E3E99;

		.nav {
			text-decoration-line: none;
			display: flex;
			line-height: 65px;
			align-items: center;
			justify-content: center;
			height: 65px;
			color: #fff;
			font-size: 20px;
			width: 280px;
		}

		.active {
			background-color: #0A4FD3;
		}

		.nav:hover {
			background-color: #0A4FD3;
		}
	}

	@media screen and (min-width: 1200px) and (max-width:1399px) {
		.nav-content {
			.nav {
				width: 240px;
			}
		}
	}

	@media screen and (max-width:768px) {
		.nav-content {
			heigh: 45px;
			.nav {
				width: 20%;
				font-size: 15px;
				height: 45px;
				line-height: 45px;
			}
		}
	}
</style>
