import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SlideVerify from 'vue-monoplasty-slide-verify'
import Viewer from 'v-viewer'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false
Vue.use(SlideVerify);
Vue.use(ElementUI);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
