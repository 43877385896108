<template>
	<div class="footer-content">
		<div class="parnter-link">
			<p class="title">友情链接：</p>
			<a href="https://www.mps.gov.cn/" target="__blank"><img class="imgga" src="@/assets/gongan.png" /></a>
			<p class="v-line"></p>
			<a href="http://www.trimps.ac.cn/gass/" target="__blank"><img class="imgss" src="@/assets/sansuo.png" /></a>
			<p class="v-line"></p>
			<a href="https://www.gaskmp.cn/" target="__blank"><img class="imgyz" src="@/assets/qgyzzhfwpt.png" /></a>
		</div>
		<div class="copy-right">
			Copyright © 2023 全国电子印章管理与服务平台分中心 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19138848号</a>
		</div>
		<!-- <div class="copy-right">
      <a class="bei" target="__blank" href="https://beian.miit.gov.cn/">粤ICP备19138848号</a>Copyright © 2019 - 2022 All Rights Reserved. 版权所有
    </div> -->
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss" scoped>
	.footer-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 140px;
		background-color: #272F3C;

		.parnter-link {
			width: 1400px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			height: 69px;
			border-bottom: 1px solid rgba(255, 255, 255, .2);

			.title {
				margin: 0;
				margin-right: 40px;
				font-size: 12px;
				color: rgba(255, 255, 255, .6);
			}

			.imgyz {
				width: 191px;
				height: 30px;
			}

			.imgss {
				width: 160px;
				height: 30px;
			}

			.imgga {
				width: 190px;
				height: 30px;
			}

			.v-line {
				width: 1px;
				margin: 0 40px;
				height: 20px;
				background-color: rgba(255, 255, 255, .3);
			}
		}

		.copy-right {
			height: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			color: rgba(255, 255, 255, .6);
			a{color: rgba(255, 255, 255, .6); margin-left: 6px; text-decoration: none;}
			.bei {
				color: inherit;
				margin-right: 20px;
			}
		}

		@media screen and (min-width: 1200px) and (max-width:1399px) {
			.parnter-link {
				width: 1200px;
			}
		}
	}

	@media screen and (max-width:768px) {
		.footer-content {
			height: auto;
			.parnter-link {
				width: 100%;
				height: 50px;
	
				.title {
					display: none;
				}
	
				.v-line {
					margin: 0 10px;
				}
	
				.imgyz {
					width: 100px;
					height: auto;
				}
	
				.imgss {
					width: 100px;
					height: auto;
				}
	
				.imgga {
					width: 100px;
					height: auto;
				}
	
			}
	
			.copy-right {
				display: block;
				height: 52px; padding: 10px 0 0 0;
				a{ display: inline-block; margin-top: 6px;}
			}
		}
	
	}
</style>
