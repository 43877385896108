import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('@/views/Home')
  },
  {
    path:'/index',
    name:'homeindex',
    redirect:'/'
  },
  {
    path:'/index.html',
    name:'homeindexhtml',
    redirect:'/'
  },
  {
    path:'/seal-query',
    name:'sealQuery',
    component:()=>import('@/views/SealQuery')
  },
  {
    path:'/verification-center',
    name:'verificationCenter',
    component:()=>import('@/views/VerificationCenter')
  },
  {
    path:'/product-center',
    name:'productCenter',
    component:()=>import('@/views/ProductCenter')
  },
  {
    path:'/contact-us',
    name:'contactUs',
    component:()=>import('@/views/ContactUs')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
