<template>
	<div class="header-box">
		<div class="header-content">
			<!--
			<div class="w">
				<el-button round plain class="login" size="mini" @click="navTo">登录/注册</el-button>
			</div>
			-->
			<div class="banner">
				<img class="banner-title" src="@/assets/banner.png" />
			</div>
			<Navigation />
		</div>
	</div>
</template>

<script>
	import Navigation from './Navigation';
	export default {
		components: {
			Navigation
		},
		methods: {
			navTo() {
				window.open('https://qg.eschain.cn/', '__blank');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.w {
		width: 1400px;
		margin: 0 auto;
		font-size: 12px;
		font-weight: normal;
	}

	.header-content {
		height: 545px;

		.w {
			text-align: right;
			position: relative;
		}

		.login {
			position: absolute;
			color: rgb(0, 81, 255);
			right: 0;
			top: 15px;
		}

		.banner {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 480px;
			background-image: url('@/assets/bannerbg.gif');
			background-size: 100% 100%;

			.banner-title {
				width: 882px;
				height: 129px;
			}
		}

		@media screen and (min-width: 1200px) and (max-width:1399px) {
			.w {
				width: 1200px;
			}
		}

	}

	@media screen and (max-width:768px) {
		.header-content {
			height: 250px;
			overflow: hidden;
			.w {
				width: 100%;
			}

			.login {
				right: 15px;
			}

			.banner {
				height: 205px;

				.banner-title {
					width: 320px;
					height: auto;
				}
			}
		}
	}
</style>
